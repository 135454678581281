import {
  AboutCard,
  AboutHolder,
  Heading,
  Img,
  ImgWrapper,
  InfoColumn,
  InfoRow,
  InfoSec,
  Subtitle,
  TextWrapper,
  TopLine,
} from "./InfoSection.elements";
import { Button, Container } from "../../globalStyles";

import { Link } from "react-router-dom";
import React from "react";

// import logo from '../../images/airbnb.svg';
function InfoSection({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  headline1,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
  disp_icons,
  disp_socials,
  logo,
  logo1,
  logo2,
  Width,
  Pic_Width,
  media_width,
  chatbot,
  chatbotM,
  extraRelatedPics,
  Compare,
}) {
  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper
                disp_icons={disp_icons}
                Width={Width}
                chatbot={chatbot}
                chatbotM={chatbotM}
              >
                <TopLine
                  data-aos="fade-up"
                  data-aos-duration="650"
                  lightTopLine={lightTopLine}
                >
                  {topLine}
                </TopLine>
                <Heading
                  data-aos="fade-down"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                  lightText={lightText}
                >
                  <span>{headline1}</span>
                  {headline}
                </Heading>
                <Subtitle
                  data-aos="fade-up"
                  data-aos-duration="650"
                  lightTextDesc={lightTextDesc}
                >
                  {description}
                </Subtitle>
                {/* <Link to="/sign-up"> */}
                <Button
                  primary
                  Color
                  borderColor={"#000"}
                  onClick={() =>
                    (window.location.href =
                      "https://vcboard.qrfds.com/register")
                  }
                >
                  {buttonLabel}
                </Button>
                {/* </Link> */}
                {/* 2nd Image small */}
                {/* <img src={logo} alt={alt} loading="lazy" /> */}
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <Img
                  Compare={Compare}
                  WEDTH={Pic_Width}
                  mWedth={media_width}
                  src={img}
                  alt={alt}
                  loading="lazy"
                />
                {extraRelatedPics?.map(({ src, alt, className, anim, key }) => (
                  <img
                    data-aos={anim?.name || "fade-down"}
                    data-aos-delay={anim?.delay || "500"}
                    data-aos-easing={anim?.ease || "ease"}
                    data-aos-duration={anim?.duration || "600"}
                    src={src}
                    alt={alt}
                    className={className}
                    key={key}
                  />
                ))}
              </ImgWrapper>
            </InfoColumn>
            {/* <InfoColumn> */}
            <AboutHolder disp_socials={disp_socials}>
              <AboutCard data-aos="fade-left" data-aos-duration="500">
                <a href="https://www.facebook.com/profile.php?id=100087394135275" target="_blank">
                  {" "}
                  <Img src={logo1} alt="FBicon" />
                </a>
              </AboutCard>
              <AboutCard data-aos="fade-left" data-aos-duration="1000">
                <a href="https://www.instagram.com/Qrfds_" target="_blank">
                  {" "}
                  <Img src={logo2} alt="IGicon" />
                </a>
              </AboutCard>
              {/* <AboutCard data-aos="fade-left"
                  data-aos-duration="1500" >
                  <Img src={logo3} alt="icon" />
                </AboutCard> */}
            </AboutHolder>
            {/* </InfoColumn> */}
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
}

export default InfoSection;
