import { Link } from "react-router-dom";
import styled from "styled-components";

// import { FaMagento } from 'react-icons/fa';


export const FooterContainer = styled.div`
  background-color: #000;
  /* padding: 2rem 0 ; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px!important;
  padding-bottom: 100px!important;
}

`;

export const FooterSubscription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
`;

export const FooterSubHeading = styled.p`
  margin-bottom: 24px;
  font-size: 24px;
`;

export const FooterSubText = styled.p`
  margin-bottom: 24px;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border: 1px solid #fff;

  &::placeholder {
    color: #242424;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

export const FooterLinksContainer = styled.div`
  width: 100%;
  /* max-width: 1000px; */
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  /* width: 160px; */
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h6`
  margin-bottom: 6px;
`;

export const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #0467fb;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  /* max-width: 1000px; */
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  /* max-width: 1000px; */
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled.a`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const SocialIcon = styled.div`
  /* margin-right: 100px; */
  /* display: none; */
  /* width: 160px; */
  /* transform: translateX(-100px); */
  width: 200px;
  /* margin-right:120px ; */
  p {
    text-align: center;
    color: #fff;
  }
  @media screen and (max-width: 820px) {
    vertical-align: left;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  display: flex;
  margin: 20px auto;
  /* top:20px; */
  /* height: 20px; */
  justify-content: space-evenly;
  /* transform: translateY(50px); */
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 700px) {
    justify-content: center;
  }
  /* width: 240px; */
  /* padding: 20px; */
`;

export const SocialIconLink = styled(Link)`
  color: #fff;
  font-size: 24px;
  padding: 0 0 0 20px;
  outline: none;
`;


export const SocialIconLink2 = styled.a`
  color: #fff;
  font-size: 24px;
  padding: 0 0 0 20px;
  outline: none;
`;